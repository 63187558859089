/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .comeos-sector.baby .sector-facts-number {
  color: #00B4E1 !important;
}
.altai-theme-content #bodyView .comeos-sector.baby .section-comeosvoordesector {
  background-color: #00B4E1 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.books .sector-facts-number {
  color: #EC0044 !important;
}
.altai-theme-content #bodyView .comeos-sector.books .section-comeosvoordesector {
  background-color: #EC0044 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.catering .sector-facts-number {
  color: #9677E5 !important;
}
.altai-theme-content #bodyView .comeos-sector.catering .section-comeosvoordesector {
  background-color: #9677E5 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.convenience .sector-facts-number {
  color: #9677E5 !important;
}
.altai-theme-content #bodyView .comeos-sector.convenience .section-comeosvoordesector {
  background-color: #9677E5 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.direct-selling .sector-facts-number {
  color: #EC0044 !important;
}
.altai-theme-content #bodyView .comeos-sector.direct-selling .section-comeosvoordesector {
  background-color: #EC0044 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.diy-garden .sector-facts-number {
  color: #FFCE00 !important;
}
.altai-theme-content #bodyView .comeos-sector.diy-garden .section-comeosvoordesector {
  background-color: #FFCE00 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.convenience .sector-facts-number {
  color: #9677E5 !important;
}
.altai-theme-content #bodyView .comeos-sector.convenience .section-comeosvoordesector {
  background-color: #9677E5 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.electro .sector-facts-number {
  color: #9677E5 !important;
}
.altai-theme-content #bodyView .comeos-sector.electro .section-comeosvoordesector {
  background-color: #9677E5 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.fashion .sector-facts-number {
  color: #00B4E1 !important;
}
.altai-theme-content #bodyView .comeos-sector.fashion .section-comeosvoordesector {
  background-color: #00B4E1 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.food .sector-facts-number {
  color: #FFCE00 !important;
}
.altai-theme-content #bodyView .comeos-sector.food .section-comeosvoordesector {
  background-color: #FFCE00 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.health-beauty .sector-facts-number {
  color: #FFCE00 !important;
}
.altai-theme-content #bodyView .comeos-sector.health-beauty .section-comeosvoordesector {
  background-color: #FFCE00 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.interior .sector-facts-number {
  color: #00B4E1 !important;
}
.altai-theme-content #bodyView .comeos-sector.interior .section-comeosvoordesector {
  background-color: #00B4E1 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.kitchen .sector-facts-number {
  color: #FFCE00 !important;
}
.altai-theme-content #bodyView .comeos-sector.kitchen .section-comeosvoordesector {
  background-color: #FFCE00 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.pharma .sector-facts-number {
  color: #19d1c6 !important;
}
.altai-theme-content #bodyView .comeos-sector.pharma .section-comeosvoordesector {
  background-color: #19d1c6 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.restaurants .sector-facts-number {
  color: #EC0044 !important;
}
.altai-theme-content #bodyView .comeos-sector.restaurants .section-comeosvoordesector {
  background-color: #EC0044 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.sports .sector-facts-number {
  color: #19d1c6 !important;
}
.altai-theme-content #bodyView .comeos-sector.sports .section-comeosvoordesector {
  background-color: #19d1c6 !important;
  color: #fff;
}
.altai-theme-content #bodyView .comeos-sector.toys .sector-facts-number {
  color: #FFCE00 !important;
}
.altai-theme-content #bodyView .comeos-sector.toys .section-comeosvoordesector {
  background-color: #FFCE00 !important;
  color: #fff;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-numbers {
  text-align: center;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts {
  text-align: center;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box {
  padding: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #EDEDED;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box .sector-facts-box-content {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box h3 {
  margin-bottom: 35px;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box .sector-facts-fact {
  padding: 40px;
  height: 200px;
  width: 200px;
  border: 0;
  border-style: solid;
  border-color: #EDEDED;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box .sector-facts-fact.box-index-3 {
  margin-top: -3px;
  margin-left: -3px;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box .sector-facts-fact p {
  margin-top: 1em;
  text-align: center !important;
  margin: 5px;
  font-size: 0.8em;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box .sector-facts-fact .sector-facts-number {
  color: #9677E5;
  font-weight: 900;
  font-size: 35px;
}
.altai-theme-content #bodyView .container-content .comeos-sector .comeos-sector-facts .sector-facts-box .sector-facts-fact .sector-facts-bold {
  font-weight: 600;
  font-size: 15px;
}
.altai-theme-content #bodyView .container-content .comeos-sector .section-comeosvoordesector .section-comeosvoordesector-icon {
  text-align: center;
  font-size: 5em;
}
.altai-theme-content #bodyView .container-content .comeos-sector .section-comeosvoordesector .section-comeosvoordesector-keyword {
  text-align: center;
}
.altai-theme-content #bodyView .container-content .comeos-sector .section-comeosvoordesector .section-comeosvoordesector-text {
  text-align: left;
  font-size: 0.9em;
}
.altai-theme-content #bodyView .container-content .comeos-sector .section-comeosvoordesector .section-comeosvoordesector-text ul {
  text-align: left !important;
}
